import React from 'react';
import './App.scss';

const App: React.FC = () => {
  return (
    <div className="App">
      <header className="App-header">
        <div className="App-header-background"/>
        <div className="App-header-text">
            <div className="App-header-text--main">Lyle Alexander <br/>Schemmerling</div>
            <div className="App-header-text--sub">Software Engineer</div>
        </div>
        <a href="mailto: contact@lyle.fyi" className="btn btn-white btn--animated">
            email me
        </a>
      </header>
    </div>
  );
};

export default App;
